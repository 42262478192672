
import {defineComponent, reactive, ref, toRefs} from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {useStore} from "vuex";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "delete-modal",
  components: {},
  props: {
    item: {
      type: Object,
      required: true
    },
    model: {
      type: String,
      required: true
    },
    endpoint: {
      type: String,
      required: true
    }
  },
  setup(props) {

    const formRef = ref<null | HTMLFormElement>(null);
    const deleteModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    let formData = reactive({
      confirmation: ""
    });
    const store = useStore();

    const rules = ref({
      confirmation: [
        {
          required: true,
          pattern: /yes/,
          message: "You must type yes for deleting",
          trigger: "change",
        },
      ]
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          ApiService.setHeader();
          return ApiService.delete(props.endpoint + "/" + props.item.id)
              .then(() => {
                store.dispatch("load"+props.endpoint.charAt(0).toUpperCase() + props.endpoint.slice(1));
                Swal.fire({
                  text: props.model + " deleted!",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Close",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  if(formRef.value){
                    formRef.value.resetFields();
                  }
                  hideModal(deleteModalRef.value);
                });
              })
              .catch((error) => {
                Swal.fire({
                  text: error.response.data.message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Close",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      deleteModalRef,
    };
  },
});
